(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/use-media-query/assets/javascripts/features.js') >= 0) return;  svs.modules.push('/components/lb-utils/use-media-query/assets/javascripts/features.js');
"use strict";


const MEDIA_QUERY_FEATURES = {
  PREFERS_REDUCED_MOTION: '(prefers-reduced-motion)',
  PREFERS_COLOR_SCHEME_LIGHT: '(prefers-color-scheme: light)',
  PREFERS_COLOR_SCHEME_DARK: '(prefers-color-scheme: dark)'
};
setGlobal('svs.components.lbUtils.useMediaQuery.features', MEDIA_QUERY_FEATURES);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/use-media-query/assets/javascripts/use-media-query.js') >= 0) return;  svs.modules.push('/components/lb-utils/use-media-query/assets/javascripts/use-media-query.js');
"use strict";


const {
  useLayoutEffect,
  useState
} = React;
const {
  only,
  values
} = svs.components.lbUtils.useMediaQuery.breakpoints;
const useMediaQuery = queryInput => {
  const query = queryInput.replace(/^@media( ?)/m, '');
  const [match, setMatch] = useState(() => matchMedia(query).matches);
  useLayoutEffect(() => {
    const queryList = matchMedia(query);
    const updateMatch = () => {
      setMatch(queryList.matches);
    };
    updateMatch();
    queryList.addListener(updateMatch);
    return () => {
      queryList.removeListener(updateMatch);
    };
  }, [query]);
  return match;
};
const useMediaQueryWatcher = () => {
  const entries = Array.from(values.keys()).map(key => {
    const query = only(key).replace(/^@media( ?)/m, '');
    const queryList = matchMedia(query);
    return {
      key,
      queryList,
      onChange: null
    };
  });
  const [match, setMatch] = useState(() => entries.find(_ref => {
    let {
      queryList
    } = _ref;
    return queryList.matches;
  }).key);
  useLayoutEffect(() => {
    entries.forEach(entry => {
      entry.onChange = event => {
        if (event.matches) {
          setMatch(entry.key);
        }
      };
      entry.queryList.addEventListener('change', entry.onChange);
    });
    return () => {
      entries.forEach(entry => entry.queryList.removeEventListener('change', entry.onChange));
    };
  }, [entries]);
  return match;
};
setGlobal('svs.components.lbUtils.useMediaQuery.useMediaQueryWatcher', useMediaQueryWatcher);
setGlobal('svs.components.lbUtils.useMediaQuery.useMediaQuery', useMediaQuery);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/use-media-query/assets/javascripts/breakpoints.js') >= 0) return;  svs.modules.push('/components/lb-utils/use-media-query/assets/javascripts/breakpoints.js');
"use strict";


const {
  BREAKPOINT_XS,
  BREAKPOINT_SM,
  BREAKPOINT_AM,
  BREAKPOINT_MD,
  BREAKPOINT_LG,
  BREAKPOINT_XL
} = svs.components.lbUtils.useMediaQuery;
const values = new Map([[BREAKPOINT_XS, 0], [BREAKPOINT_SM, 480], [BREAKPOINT_AM, 768], [BREAKPOINT_MD, 1024], [BREAKPOINT_LG, 1357], [BREAKPOINT_XL, 1600]]);
const keys = Array.from(values.keys());
const getMin = breakpoint => values.get(breakpoint);

const getMax = breakpoint => values.get(breakpoint) - 0.02;
const up = key => {
  const value = values.has(key) ? getMin(key) : key;
  return "@media (min-width:".concat(value, "px)");
};
const down = key => {
  const value = values.has(key) ? getMax(key) : key;
  return "@media (max-width:".concat(value, "px)");
};
const between = (start, end) => {
  const minWidth = values.has(start) ? getMin(start) : start;
  const maxWidth = values.has(end) ? getMax(end) : end;
  return "@media (min-width:".concat(minWidth, "px) and (max-width:").concat(maxWidth, "px)");
};
function only(key) {
  const index = keys.indexOf(key);
  if (index + 1 === keys.length) {
    return up(key);
  }
  return between(key, keys[index + 1]);
}
const breakpoints = {
  up,
  down,
  between,
  only,
  values
};
setGlobal('svs.components.lbUtils.useMediaQuery.breakpoints', breakpoints);

 })(window);
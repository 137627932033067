(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/use-media-query/assets/javascripts/values.js') >= 0) return;  svs.modules.push('/components/lb-utils/use-media-query/assets/javascripts/values.js');
"use strict";


const values = {
  BREAKPOINT_XS: 'xs',
  BREAKPOINT_SM: 'sm',
  BREAKPOINT_AM: 'am',
  BREAKPOINT_MD: 'md',
  BREAKPOINT_LG: 'lg',
  BREAKPOINT_XL: 'xl'
};

for (const key in values) {
  setGlobal("svs.components.lbUtils.useMediaQuery.".concat(key), values[key]);
}

 })(window);